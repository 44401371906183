<template>
  <b-card>
    <g-table
      id="maintable"
      ref="bank-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true , permission: 'addBanks'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({
            id: 0,
            englishName: '',
          });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="currentBranch.id === item.branchId ? $t('edit') : $t('preview')"
            v-permission="'editBanks'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              :icon="currentBranch.id === item.branchId ? 'EditIcon' : 'EyeIcon'"
              class="mx-1 clickable"
            />
          </b-button>
          <b-button
            v-if="currentBranch.id === item.branchId"
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteBanks'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
    <b-sidebar
      id="sidebar-edit"
      ref="sidebar"
      no-close-on-backdrop
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
      <template #default>
        <div
          class="d-flex justify-content-between bg-primary align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-white">
            {{ selectedItem.id > 0 ? $t("editBank") : $t("newBank") }}
          </h5>
        </div>
        <b-card>
          <g-form @submit="save">
            <b-row>
              <b-col
                v-if="selectedItem.id > 0"
                md="12"
              >
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="customer"
                >
                  {{ $t("code") }}
                </label>
                <!-- code  -->
                <g-field
                  id="code"
                  type="number"
                  :value.sync="selectedItem.code"
                  name="code"
                  disabled
                />
              </b-col>

              <b-col md="12">
                <!-- arabicName  -->
                <g-field
                  id="arabicName"
                  ref="arabicName"
                  rules="required"
                  :value.sync="selectedItem.arabicName"
                  name="arabicName"
                  label-text="arabicName"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <g-field
                  id="englishName"
                  ref="englishName"
                  :value.sync="selectedItem.englishName"
                  name="englishName"
                  label-text="englishName"
                />
              </b-col>
              <b-col md="12">
                <!-- adress  -->
                <g-field
                  id="adress"
                  :value.sync="selectedItem.address"
                  name="adress"
                  label-text="adress"
                />
              </b-col>
            </b-row>
            <b-row>
              <!-- accountNumber -->
              <b-col md="12">
                <g-field
                  id="accountNumber"
                  :value.sync="selectedItem.accountNumber"
                  name="accountNumber"
                  label-text="accountNumber"
                />
              </b-col>
              <!-- iban  -->
              <b-col md="12">
                <g-field
                  id="iban"
                  :value.sync="selectedItem.iban"
                  label-text="iban"
                  name="iban"
                />
              </b-col>
            </b-row>

            <!-- account number  -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    field="select"
                    label="arabicName"
                    label-text="accountingLinkage"
                    name="accountId"
                    :value.sync="selectedItem.accountId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="accounts"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="general"
                >
                  {{ $t("general") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isShared"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="checkIfCurrentItemUsedInAnotherBranch()"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label
                    style="font-size: 14px; margin-bottom: 7px"
                    for="customer"
                  >
                    {{ $t("notes") }}
                  </label>
                  <b-form-textarea
                    id="textarea"
                    v-model="selectedItem.notes"
                    label="Notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  v-if="currentBranch.id === selectedItem.branchId || selectedItem.id === 0"
                  v-permission="$route.meta.permission"
                  class="mx-1"
                  type="submit"
                  variant="primary"
                  data-action-type="save"
                >
                  {{ $t("save") }}
                </b-button>
                <b-button
                  class="ml-1"
                  variant="secondary"
                  @click="closeModal"
                >
                  {{ $t("Close") }}
                </b-button>
              </b-col>
            </b-row>
          </g-form>
          <div
            v-if="helper.showExcelWithTransactions"
            class="center-element flex-column mt-2"
          >
            <vue-excel-xlsx
              :data="helper.transactionsInAnotherBranch"
              :columns="sharedTransactionsColumns"
              :filename="fileName"
              :sheetname="'xlsxSheet'"
              class="btn btn-relief-success ml-1 p-0 btn-sm"
            >
              <img
                src="@/assets/images/icons/xls.jpg"
                alt=""
                style="height: 39px; width: auto"
              >
            </vue-excel-xlsx>
            <label
              class="main-space mt-1 mb-2"
              style="color: #177219;"
            >
              {{ $t('sharedTransactionHint') }} </label>
          </div>
        </b-card>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
      selectedItem: {},
      modal: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      accounts: [],
      banks: [],
      paymentMethods: [],
      sharedItems: [],
      helper: {
        isCurrentStateShared: false,
        transactionsInAnotherBranch: [],
        showExcelWithTransactions: false
      }
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: 'arabicName',
          field: 'arabicName',
          label: this.$t('bankName'),
          sortable: true,
        },
        {
          key: 'accountNumber',
          field: 'accountNumber',
          label: this.$t('accountNumber'),
          sortable: true,
        },
        { key: 'accountCode', label: this.$t('accountingLinkage'), sortable: true },
        { key: 'actions' },
      ];
    },

    sharedTransactionsColumns() {
      return [
        { key: 'branchNameAr', field: 'branchNameAr', label: this.$t('branchName') },
        { key: 'sourceValue', field: 'sourceValue', label: this.$t('transactionNumber') },
        { key: 'sourceName', field: 'sourceName', label: this.$t('invoiceType') },
      ];
    },

    fileName() {
      return `${this.$t('sharedTransactionsForBank')} - ${this.selectedItem.arabicName}`
    }
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getItemsBasedOnCurrentBranch(data) {
      return data.filter((val) => val.branchId === this.currentBranch.id);
    },
    getAccounts() {
      this.get({ url: 'accounts' }).then((data) => {
        this.accounts = data.filter((acc) => acc.type === 'sub');
      }).then(() => {
        this.getItems();
      })
    },
    getItems() {
      this.get({ url: 'Banks' }).then((data) => {
        // get all items
        this.items = data;
        this.items.forEach(element => {
          const accObj = this.accounts.find((val) => val.id === element.accountId) || { code: '' }
          element.accountCode = accObj.code;
        });

        // is existing shared entity
        this.sharedItems = data.filter((x) => x.branchId !== this.currentBranch.id);

        // if any then show it for user
        if (this.sharedItems.length > 0) {
          this.tableColumns.splice(4, 0, {
              key: 'isShared',
              keyfield: 'isShared',
              label: this.$t('general'),
              formatter: (key, value, item) => {
                if (item.branchId !== this.currentBranch.id) {
                  return `<span class=""><img src="${require('@/assets/images/icons/valid.png')}" width="25" alt="isShared"></span>`
                }
              },
          })
          this.tableColumns.splice(5, 0, {
              key: 'baseBranch',
              kefieldy: 'baseBranch',
              label: this.$t('branchName'),
              formatter: (key, value, item) => {
                if (item.branchId !== this.currentBranch.id) {
                  return `<span class="p-50 main-space badge bg-gradient-primary bg-darken-1">${this.branches.find((x) => x.id === item.branchId).arabicName}</span>`;
                }
              },
          })
        }
      });

      this.get({ url: 'PaymentMethods' }).then((data) => {
        this.paymentMethods = data;
      });
    },

    save() {
      // check if it used in another branch while it is shared
      if (this.helper.isCurrentStateShared && !this.selectedItem.isShared && this.helper.transactionsInAnotherBranch.length > 0) {
        this.doneAlert({
            title: this.$t('cantUpdate'),
            text: this.$t('usedInTransactionsInAnotherBranches', { name: this.isRight ? this.selectedItem.arabicName : this.selectedItem.englishName }),
            type: 'error',
            timer: 10000
        })
        this.helper.showExcelWithTransactions = true;
        return;
      }

      // check if related payment method is shared and trying to make bank un-shared
      if (this.helper.isCurrentStateShared && !this.selectedItem.isShared && this.selectedItem.isRelatedPaymentMethodShared) {
        this.doneAlert({
            text: this.$t('sharedForBankCantBeModified', { paymentMethodName: this.isRight ? this.selectedItem.paymentMethodArabicName : this.selectedItem.paymentMethodEnglishName }),
            type: 'warning',
            timer: 10000
        })
        return;
      }

      // then save
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Banks',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'Banks', data: this.selectedItem }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          // validate if bank used in payment method
          if (this.isBankUsed(item.id)) {
            return;
          }

          // then delete
          this.delete({ url: 'Banks', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },

    isBankUsed(bankId) {
      const relatedPayMethod = this.paymentMethods.find((val) => val.bankId === bankId);
      if (relatedPayMethod) {
        this.doneAlert({ text: `${this.$t('bankRelatedToPaymentMethod')} ${relatedPayMethod.arabicName}`, type: 'warning' });
        return true;
      }

      return false;
    },

    closeModal() {
      // reset
      this.helper = {
        isCurrentStateShared: false,
        transactionsInAnotherBranch: [],
        showExcelWithTransactions: false
      }
      this.$refs.sidebar.hide();
    },
    openModal(item) {
      this.helper.isCurrentStateShared = item.isShared;
      this.selectedItem = { ...item };
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit')
    },
    checkIfCurrentItemUsedInAnotherBranch() {
      if (this.branches.length > 1 && this.currentBranch.id === this.selectedItem.branchId && this.helper.isCurrentStateShared && !this.selectedItem.isShared) {
        this.get({ url: 'Banks/getSharedBanks', id: this.selectedItem.id }).then((data) => {
          this.helper.transactionsInAnotherBranch = data;
          this.helper.transactionsInAnotherBranch.forEach(element => {
            element.sourceName = this.$t(element.sourceType)
          });
        });
      }
    }
  },
};
</script>

<style></style>
